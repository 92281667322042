import type { Order } from '@commercelayer/sdk';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useGetOrder } from 'shared/hooks/orders/useGetOrder';
import { useResetOrderState } from 'shared/hooks/orders/useResetOrderState';
import * as clOrderAPI from 'shared/infra/commerceLayer/orders';
import {
  shouldResetOrder,
  shouldUnplaceOrder,
  unplaceOrder,
} from 'shared/infra/storefront/orders';
import logger from 'shared/services/logger';
import {
  dispatchChangeProductQuantityEvent,
  dispatchChangeProductQuantityFailureEvent,
  dispatchRemoveProductEvent,
  dispatchRemoveProductFailureEvent,
} from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';
import {
  changeProductQuantity,
  changeProductQuantityFailure,
  changeProductQuantitySuccess,
  removeProduct,
  removeProductFailure,
  removeProductSuccess,
} from 'shared/store/order/actions';

export interface ProductInfo {
  id: string;
  trackingId: string;
  reference?: string;
  formattedUnitAmount?: string;
}

type UseLineItems = {
  changeLineItemQuantity: (
    product: ProductInfo,
    quantity: number,
  ) => Promise<Order>;
  changeLineItemQuantityDebounced: (
    product: ProductInfo,
    quantity: number,
  ) => Promise<Order>;
  loading: boolean;
  removeLineItem: (product: ProductInfo) => Promise<void>;
};

export const useLineItems = (): UseLineItems => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getOrder = useGetOrder();
  const resetOrder = useResetOrderState();

  const localOrder = useTypedSelector((state) => state.order.orderDetails);

  const removeLineItem = useCallback(
    async (product: ProductInfo) => {
      try {
        // TODO: this dispatch only sets the state to
        // loading. We must revisit our loading strategy.
        dispatch(removeProduct(product.reference));

        await clOrderAPI.removeLineItems(product.id);

        dispatch(removeProductSuccess(product.id));
        void dispatchRemoveProductEvent({
          product: {
            id: product.reference,
            // TODO: check if this is correct or needs catalog info?
            trackingId: product.trackingId,
          },
        });
      } catch (e) {
        logger.error(e);
        dispatch(
          removeProductFailure(
            product.id,
            'Unable to remove product from order.',
          ),
        );

        void dispatchRemoveProductFailureEvent({
          product: {
            id: product.id,
            trackingId: product.trackingId,
          },
        });
      }
    },
    [dispatch],
  );

  const adjustLineItemQuantity = useCallback(
    async (product: ProductInfo, quantity: number) => {
      try {
        // only sets the loading state correctly.
        dispatch(
          changeProductQuantity(product.id, quantity, product.reference),
        );

        await clOrderAPI.updateLineItems(product.id, { quantity });

        dispatch(changeProductQuantitySuccess());
        void dispatchChangeProductQuantityEvent({
          product: {
            id: product.reference,
            trackingId: product.trackingId,
            quantity,
            price: product.formattedUnitAmount,
          },
        });
      } catch (e) {
        logger.error(e);
        dispatch(changeProductQuantityFailure());
        void dispatchChangeProductQuantityFailureEvent({
          product: {
            id: product.reference,
            trackingId: product.trackingId,
            quantity,
            price: product.formattedUnitAmount,
          },
        });
      }
    },
    [dispatch],
  );

  const ensureLineItemQuantity = useCallback(
    (product: ProductInfo, quantity: number) => {
      if (quantity === 0) {
        return removeLineItem(product);
      }

      return adjustLineItemQuantity(product, quantity);
    },
    [removeLineItem, adjustLineItemQuantity],
  );

  const changeLineItemQuantity = useCallback(
    async (product: ProductInfo, quantity: number): Promise<Order> => {
      setLoading(true);
      await ensureLineItemQuantity(product, quantity);
      await clOrderAPI.updateTaxes(localOrder.id);
      setLoading(false);

      const order = await getOrder(localOrder.id);

      if (shouldResetOrder(order.status, order?.payment_method?.reference)) {
        resetOrder();

        return null;
      }

      if (shouldUnplaceOrder(order.status, order?.payment_method?.reference)) {
        await unplaceOrder(order.id);
      }

      return order;
    },
    [ensureLineItemQuantity, getOrder, localOrder.id, resetOrder],
  );

  // eslint-disable-next-line
  const changeLineItemQuantityDebounced = useCallback(
    debounce(changeLineItemQuantity, 500, { leading: true }),
    // must be the same as changeLineItemQuantity
    [ensureLineItemQuantity, localOrder.id],
  );

  return {
    loading,
    removeLineItem,
    changeLineItemQuantity,
    changeLineItemQuantityDebounced,
  };
};
