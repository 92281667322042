import type { Order } from '@commercelayer/sdk';

import type { LineItemType } from 'productSelection/types/products';
import * as OrderAPI from 'shared/infra/commerceLayer/orders';
import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';
import {
  dispatchAddProductEvent,
  dispatchAddProductFailureEvent,
  dispatchRemoveProductEvent,
  dispatchRemoveProductFailureEvent,
} from 'shared/services/tracker/events';
import type { ManageProductCustomData } from 'shared/services/tracker/types';
import type { CatalogState } from 'shared/store/catalog/types';
import type { OrderLineItem } from 'shared/store/order/types';

type BusinessAccountItemReq = {
  enabled: boolean;
  selected: boolean;
  code: string;
  localizedName: string;
  lineItemType: LineItemType;
  contentfulId: string;
};

export type AddProductRequest = {
  id: string;
  code: string;
  reference: string;
  trackingId: string;
  formattedUnitAmount: string;
  quantity: number;
  couponCode?: string;
  contentfulId: string;
  allowOnlyOne: boolean;
  locale: string;
  lineItemType: LineItemType;
  localizedName?: string;
  businessAccount?: BusinessAccountItemReq;
};

type TrackLineItemReq = {
  reference: string;
  trackingId: string;
  quantity: number;
  price: string;
  code: string;
};

export type AddLineItemReq = TrackLineItemReq & {
  orderId: string;
  code: string;
  lineItemType: LineItemType;
  localizedName?: string;
};

type RemoveLineItemReq = TrackLineItemReq & {
  id: string;
};

const buildTrackEvent = (
  lineItem: TrackLineItemReq,
): ManageProductCustomData => ({
  product: {
    id: lineItem.reference,
    trackingId: lineItem.trackingId,
    quantity: lineItem.quantity,
    price: lineItem.price,
    skuCode: lineItem.code,
  },
  currency: '',
});

const removeLineItem = (req: RemoveLineItemReq): Promise<void> => {
  const trackEvent = buildTrackEvent(req);

  try {
    void dispatchRemoveProductEvent(trackEvent);

    return OrderAPI.removeProduct(req.id);
  } catch (err) {
    void dispatchRemoveProductFailureEvent(trackEvent);

    throw err;
  }
};

export const addLineItem = async (req: AddLineItemReq): Promise<void> => {
  const trackEvent = buildTrackEvent(req);

  try {
    const lineItem = await OrderAPI.addProduct(
      req.orderId,
      req.code,
      req.lineItemType,
      req.quantity,
      req.reference,
      req.localizedName,
    );

    trackEvent.currency = lineItem.currency_code;
    void dispatchAddProductEvent(trackEvent);
  } catch (err) {
    void dispatchAddProductFailureEvent(trackEvent);

    throw err;
  }
};

const ensureBusinessAccountLineItemCreated = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  const {
    enabled = false,
    selected = false,
    contentfulId = '',
    code = '',
    localizedName = '',
    lineItemType = 'sku',
  } = req.businessAccount || {};

  if (!enabled) {
    return undefined;
  }

  const businessAccountProduct = orderLineItems.find(
    ({ code: skuCode }) => skuCode === code,
  );

  if (selected && !businessAccountProduct) {
    const addLineItemReq = {
      orderId,
      code,
      quantity: 1,
      localizedName,
      reference: contentfulId,
      formattedTotalAmount: catalog[contentfulId].formattedAmount,
      trackingId: catalog[contentfulId]?.trackingId,
      lineItemType,
      price: catalog[contentfulId]?.formattedAmount,
    };

    return addLineItem(addLineItemReq);
  }

  if (!selected && businessAccountProduct) {
    const removeLineItemReq: RemoveLineItemReq = {
      id: businessAccountProduct.id,
      reference: contentfulId,
      quantity: 1,
      trackingId: catalog[contentfulId]?.trackingId,
      price: catalog[contentfulId]?.formattedAmount,
      code: req.code,
    };

    return removeLineItem(removeLineItemReq);
  }

  return undefined;
};

const cleanupRegularLineItems = async (
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  if (!req.allowOnlyOne) {
    return;
  }

  const regularLineItems = orderLineItems.filter(
    (product) => product.code !== req?.businessAccount?.code,
  );

  await Promise.all(
    regularLineItems.map((product) => {
      const removeLineItemReq: RemoveLineItemReq = {
        id: product.id,
        reference: product.reference,
        quantity: product.quantity,
        trackingId: catalog[product.reference]?.trackingId,
        price: catalog[product.reference]?.formattedAmount,
        code: product.code,
      };

      return removeLineItem(removeLineItemReq);
    }),
  );
};

const ensureRegularLineItemsCreated = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  await cleanupRegularLineItems(req, orderLineItems, catalog);
  await addLineItem({
    orderId,
    code: req.code,
    quantity: req.quantity,
    reference: req.contentfulId,
    localizedName: req.localizedName,
    price: catalog[req.contentfulId]?.formattedAmount,
    trackingId: catalog[req.contentfulId]?.trackingId,
    lineItemType: req.lineItemType,
  });
};

const ensureCouponCodeAttached = async (
  orderId: string,
  couponCode: string,
): Promise<Order> => {
  if (!couponCode && couponCode !== '') {
    return undefined;
  }

  return OrderAPI.updateOrder(orderId, {
    coupon_code: processCouponCodeToOrder(couponCode),
    _update_taxes: true,
  });
};

export const addProduct = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  await ensureRegularLineItemsCreated(orderId, req, orderLineItems, catalog);
  await ensureBusinessAccountLineItemCreated(
    orderId,
    req,
    orderLineItems,
    catalog,
  );
  await ensureCouponCodeAttached(orderId, req.couponCode);
};
