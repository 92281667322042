import { useDispatch } from 'react-redux';

import {
  getPromotionsByCouponCode,
  type Promotion,
} from 'shared/infra/commerceLayer/promotions';
import {
  loadPromotionsFailure,
  loadPromotionsSuccess,
} from 'shared/store/promotions/actions';

interface UseGetPromotions {
  getPromotions: (coupons: string[]) => Promise<Record<string, Promotion>>;
}

const useGetPromotions = (): UseGetPromotions['getPromotions'] => {
  const dispatch = useDispatch();
  const getPromotions = async (
    coupons: string[],
  ): Promise<Record<string, Promotion> | undefined> => {
    try {
      const promotionsBySku = await getPromotionsByCouponCode(coupons);
      dispatch(loadPromotionsSuccess(promotionsBySku));

      return promotionsBySku;
    } catch (error) {
      dispatch(loadPromotionsFailure('Error loading promotions.'));
    }

    return undefined;
  };

  return getPromotions;
};

export default useGetPromotions;
